<div class="container-fluid">
  <thead>
  <div class="header-space">&nbsp;</div>
  </thead>
  <div class="header">
    <div class="row mt-5">
      <div class="col-2 ml-4">
        <img src="../../../../../assets/img/AFTC%20logo.png" style="height: 150px; width: 150px">
      </div>
      <div class="col-7">
        <p class="text"></p>
        <p class="text1">AGRO FOOD TRADE CENTRE</p>
        <p class="text2">(Formerly: Tamilnadu Foodgrains Marketing Yard)</p>
        <p class="text2">[Special Purpose Vehicle (SPV) of Cereals, Pulses & Staples (CPS) Cluster]</p>
        <p class="text4">வேளாண் உணவு வர்த்தக மையம்</p>
        <p class="text3">சிக்கந்தர் சாவடி, அலங்காநல்லூர் ரோடு, மதுரை - 625018.</p>
        <p class="text5">https://aftc.in/ &nbsp;&nbsp; 0452-2660669 &nbsp;&nbsp; E-mail:
          office@aftc.in</p>
        <p class="text7">GSTIN:33AABCT1101F1Z7&nbsp;&nbsp;CIN:U74999TN1993NPL025771<br>
          FSSAI No:12419012001801</p>
      </div>
      <hr style="width:100%; text-align:left;  border-width:3px; border-color: black;">
    </div>
  </div>
  <div *ngIf="currentGatePass.tollgateVehicleDetails.vehType == 'Truck'">
    <div class="row mt-5">
      <div class="col-4 ml-2">
        <div class="d-flex justify-content-start">
          <span class="content1">No: <b>{{currentGatePass.irmNo}}</b></span>
        </div>
      </div>
      <div class="col-3 text-center">
        <span *ngIf="currentGatePass.serviceType == 'Depot'"
              class="content1 underline"><b>{{currentGatePass.serviceType}}</b></span>
        <span *ngIf="currentGatePass.serviceType == 'Warehouse'"
              class="content1 underline"><b>{{currentGatePass.storageType}}</b></span>
      </div>
      <div class="col-3">
        <div class="d-flex justify-content-end">
          <span class="content1">Date: <b>{{currentGatePass.createdAt | date: 'dd/MM/yyyy'}}</b></span>
        </div>
      </div>
    </div>
    <div class="container row ">
      <div class="col-1"></div>
      <div class="col-8 mr-5 row d-flex justify-content-center">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>Member Name :</td>
            <td><b>{{currentGatePass.memberName}}</b></td>
          </tr>
          <tr>
            <td>Type of vehicle :</td>
            <td *ngIf="currentGatePass.tollgateVehicleDetails.wheelCount == 6">
              <b>{{currentGatePass.tollgateVehicleDetails.vehType}} (Upto 6 Wheels)</b></td>
            <td *ngIf="currentGatePass.tollgateVehicleDetails.wheelCount > 6">
              <b>{{currentGatePass.tollgateVehicleDetails.vehType}} (More than 6 Wheels)</b></td>
          </tr>
          <tr>
            <td>Vehicle No :</td>
            <td><b>{{currentGatePass.tollgateVehicleDetails.vehNumberPlate}}</b></td>
          </tr>
          <tr>
            <td>Commodity :</td>
            <td *ngIf="currentGatePass.commodityName != ''"><b>{{currentGatePass.commodityName}}</b></td>
            <td *ngIf="currentGatePass.commodityName == ''"><b> - </b></td>
          </tr>
          <tr>
            <td>Weights in MT :</td>
            <td><b>{{currentGatePass.weightMt}}</b></td>
          </tr>
          <tr>
            <td>No of Units / Bags :</td>
            <td><b>{{currentGatePass.bagsUnits}}</b></td>
          </tr>
          <tr>
            <td>Supplier Name :</td>
            <td *ngIf="currentGatePass.consignorSupplierName != ''"><b>{{currentGatePass.consignorSupplierName}}</b>
            </td>
            <td *ngIf="currentGatePass.consignorSupplierName == ''"><b> - </b></td>
          </tr>
          <tr>
            <td>Place of Dispatch / Origin :</td>
            <td><b>{{currentGatePass.placeDispatchOrigin}}</b></td>
          </tr>
          <tr>
            <td>Delivery Challen / E way bill no/<br>Self Declaration :</td>
            <td *ngIf="currentGatePass.deliveryWayBill != ''"><b>{{currentGatePass.deliveryWayBill}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{declarationDate | date: 'dd/MM/yyyy'}}</b>
            </td>
            <td *ngIf="currentGatePass.deliveryWayBill == ''"><b> - </b></td>
          </tr>
          <tr>
            <td>Type :</td>
            <td *ngIf="currentGatePass.purpose == 'Loading'"><b>Empty</b></td>
            <td *ngIf="currentGatePass.purpose == 'Unloading'"><b>With Goods</b></td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>Internal Road Maintenance Charges Inclusive of GST Received Rs. <b
              style="font-size: 30px">{{currentGatePass.charges}}</b> Only
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>Time In :</td>
            <td><b>{{currentGatePass.vehEntryDateTime | date: 'HH:mm'}}</b></td>
          </tr>
          <tr>
            <td>Member / Representative Name & Phone No:</td>
            <td><b>{{currentGatePass.tollgateVehicleDetails.driverName}}
              / {{currentGatePass.tollgateVehicleDetails.driverPhNo}}</b></td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Signature of Representative</td>
            <td>For AFTC</td>
            <td>Signature of Accountant</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
    *ngIf="currentGatePass.tollgateVehicleDetails.vehType == 'Auto' || currentGatePass.tollgateVehicleDetails.vehType == 'Van'">
    <div class="row mt-5">
      <div class="col-6">
        <div class="col-6 ml-5">
          <div class="d-flex justify-content-start">
            <span class="content1">No: <b>{{currentGatePass.irmNo}}</b></span>
          </div>
        </div>
        <div class="col-6 ml-5">
          <div class="d-flex justify-content-start">
            <span class="content1">Date: <b>{{currentDate}}</b></span>
          </div>
        </div>
        <div class="col-12 ml-5">
          <div class="d-flex justify-content-start">
            <span *ngIf="currentGatePass.serviceType == 'Depot'" class="content1"><b>{{currentGatePass.serviceType}}</b></span>
            <span *ngIf="currentGatePass.serviceType == 'Warehouse'"
                  class="content1"><b>{{currentGatePass.storageType}}</b></span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-5 text-center" style="border: 1px solid #bdbdbd;">
            <h1>Rs.{{currentGatePass.charges}}</h1>
            <span style="font-size: 20px">Including GST @18%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-3">
        <div class="col-1"></div>
        <div class="col-8 mr-5 row d-flex justify-content-center">
          <table class="table table-bordered">
            <tbody>
            <tr>
              <td>Member Name :</td>
              <td colspan="3"><b>{{currentGatePass.memberName}}</b></td>
            </tr>
            <tr>
              <td>Vehicle No :</td>
              <td><b>{{currentGatePass.tollgateVehicleDetails.vehNumberPlate}}</b></td>
              <td>Type of vehicle :</td>
              <td><b>{{currentGatePass.tollgateVehicleDetails.vehType}}</b></td>
            </tr>
            <tr>
              <td>Driver Name :</td>
              <td><b>{{currentGatePass.tollgateVehicleDetails.driverName}}</b></td>
              <td>Mobile No :</td>
              <td><b>{{currentGatePass.tollgateVehicleDetails.driverPhNo}}</b></td>
            </tr>
            <tr>
              <td>Time In :</td>
              <td><b>{{currentGatePass.vehEntryDateTime | date: 'HH:mm'}}</b></td>
              <td>Time Out :</td>
              <td><b></b></td>
            </tr>
            <tr>
              <td>Signature of Security Officer :</td>
              <!--                <td colspan="2"></td>-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <hr class="mt-5" style="width:40%; margin-right: 150px; border-width:1px; border-color: black;">
    </div>
    <div class="row text-center">
      <label class="content"><i style="margin-right: 150px">Valid for single trip only</i></label>
    </div>
  </div>
</div>
