<div class="container-fulid col-lg-12">
  <div class="container">

    <div class="row py-2">
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" (click)="newEntry()">Add New Member</button>
      </div>
    </div>


    <div class="row">
      <div class="col-md-3 justify-content-start">
        <div class="form-floating">
          <select class="form-select" aria-label="Default select example" (change)="mutipleFilter($event,'payment')"
            placeholder="Payment Type">
            <option selected>Select Any One</option>
            <option value="AFTC">Through AFTC</option>
            <option value="ONLINE">Online</option>
          </select>
          <label>Payment Type</label>
        </div>
      </div>
      <!-- <div class="col-md-3">
        <div class="form-floating">
          <select class="form-select" aria-label="Default select example" (change)="mutipleFilter($event,'date')"
            placeholder="Date Filter">
            <option selected>Select Date</option>
            <option *ngFor="let date of this.registrionDate">{{date}}</option>
          </select>
          <label>Date Filter</label>
        </div>
      </div> -->
      <div class="col-md-3">
        <div class="form-floating">
          <input class="form-control" type="date" placeholder="" (change)="dateFilter($event)">
          <label>Date Filter</label>
        </div>
      </div>
      <div class="col d-flex justify-content-end">
        <div class="form-floating">
          <input type="search" class="form-control" placeholder="Search" [(ngModel)]="searchText">
          <label>Search</label>
        </div>
      </div>
    </div>

  </div>

  <div class="py-2"></div>
  <div class="">
    <div class="table-responsive">

      <table class="table main" *ngIf="this.onlineShow">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Applicant Name</th>
            <!--<th>Type</th>-->
            <th>Whatsapp No</th>
            <!--<th>City</th>-->
            <!--<th>Email Id</th>-->
            <th>Membership Type</th>
            <th>Payment Type</th>
            <th>Payment Status</th>
            <th>Transaction ID</th>
            <th>Transaction Date</th>
            <th>Sent Receipt</th>
            <!-- <th>Sent WhatsApp</th> -->
            <th>Download Receipt</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody *ngIf="this.allApplicationDetails.length > 0; else noDataFound">
          <tr *ngFor="let aftc of allApplicationDetails | filter: searchText;let i = index">
            <td>{{i + 1}}</td>
            <td>{{aftc.applicantName}}</td>
           <!-- <td>{{aftc.orgType}}</td>-->
            <td>{{aftc.whatsappNo}}</td>
           <!-- <td>{{aftc.city}}</td>-->
            <!--<td>{{aftc.emailId}}</td>-->
            <td>{{aftc.memberPeriod}}</td>
            <td>{{aftc.transactionDetails.paymentMode}}</td>
            <td>{{aftc.transactionDetails.status}}</td>
            <td>{{aftc.transactionDetails.bankTxnId}}</td>
            <td>{{aftc.createdAt }}</td>
            <td><i *ngIf="aftc.transactionDetails.status == 'SUCCESS'" (click)="sendEmail(aftc.membershipDetailsId)" class="fas fa-paper-plane"></i></td>
            <!-- <td><svg (click)="sendWhatsapp(aftc)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg></td> -->
            <td><i *ngIf="aftc.transactionDetails.status == 'SUCCESS'" (click)="downloadFile(aftc.membershipDetailsId)" class="fas fa-download"></i></td>
            <td>
              <i class="fa fa-eye" data-toggle="tooltip" data-placement="top" title="Click to View the Report"
                (click)="openModal(aftc.membershipDetailsId);"></i>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr>
            <td colspan="11">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
      <table class="table main" *ngIf="this.aftcShow">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Applicant Name</th>
            <!-- <th>Type</th> -->
            <!-- <th>Whatsapp No</th> -->
            <!-- <th>City</th>
            <th>Email Id</th> -->
            <th>Membership Type</th>
            <th>Payment Type</th>
            <th>Sent Receipt</th>
            <th>Sent WhatsApp</th>
            <th>Download Receipt</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody *ngIf="this.allApplicationDetails.length > 0; else noDataFound">
          <tr *ngFor="let aftc of allApplicationDetails | filter: searchText;let i = index">
            <td>{{i + 1}}</td>
            <td>{{aftc.applicantName}}</td>
            <!-- <td>{{aftc.orgType}}</td> -->
            <td>{{aftc.whatsappNo}}</td>
            <!-- <td>{{aftc.city}}</td>
            <td>{{aftc.emailId}}</td> -->
            <td>{{aftc.memberPeriod}}</td>
            <td>{{aftc.paymentType}}</td>
            <td><i *ngIf="aftc.transactionDetails.status == 'SUCCESS'" (click)="sendEmail(aftc.membershipDetailsId)" class="fas fa-paper-plane"></i></td>
            <!-- <td><svg (click)="sendWhatsapp(aftc)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg></td> -->
            <td><i *ngIf="aftc.transactionDetails.status == 'SUCCESS'" (click)="downloadFile(aftc.membershipDetailsId)" class="fas fa-download"></i></td>
            <td>
              <i class="fa fa-eye" data-toggle="tooltip" data-placement="top" title="Click to View the Report"
                (click)="openModal(aftc.membershipDetailsId);"></i>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr>
            <td colspan="11">Data Not Found</td>
          </tr>
        </ng-template>
      </table>

    </div>
  </div>
</div>

<div class="modal bg-light bg-opacity-50" aria-hidden="true" [style.display]="isModalShow ? 'block' : 'none'">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center fw-bolder fs-4 text-uppercase" style="color: #37517e;">test report view
          screen</h5>
        <button type="button" class="close text-danger card bg-red" data-dismiss="modal" aria-label="Close"
          (click)="isModalShow = false;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="container-fluid">
          <div class="container-fluid" style="background: #37517e">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-1 mt-1">
                <img src="../../../../assets/img/final-chamber.png" style="width:130px; height:140px" alt="">
              </div>
              <div class="col-md-10 text-center mt-3">
                <h5 class="text-bold" style="color: white; margin-bottom: 3px;">AGRO FOOD CHAMBER OF COMMERCE & INDUSTRY
                </h5>
                <h6 class="text-bold" style="color: white; margin-bottom: 3px;">(Agro Food Chamber)</h6>
                <h6 class="" style="color: white; margin-bottom: -3px;">Agro Food Trade Centre Campus</h6>
                <h6 class="mt-1" style="color: white; margin-bottom: -3px;">Sikkanthar Chavadi, Alanganallur Road,
                  MADURAI – 625
                  018.</h6>
                <h6 class="" style="color: white;"><img src="../../../../assets/img/icons/icons8-outgoing-call-100.png"
                    style="height: 25px;width: 25px;"> +91 63693 21492
                  <span class="ml-2" style="white-space: nowrap;"><img
                      src="../../../../assets/img/icons/icons8-email-64.png" style="height: 30px;width: 30px;">
                    contact@agrofoodchamber.com</span>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="table-responsive">

            <table class="table table-bordered ">
              <tbody *ngFor="let member of getApplicantionDetails">
                <tr>
                  <th>Applicant Name</th>
                  <td>{{member.applicantName}}</td>
                </tr>
                <tr>
                  <th>Membership Type</th>
                  <td>{{member.memberPeriod}}</td>
                </tr>
                <tr>
                  <th>Organization Name</th>
                  <td>
                    <p *ngIf="member.organizationName == null  || member.organizationName == ''">
                      --</p>
                    <p *ngIf="member.organizationName != null">{{member.organizationName}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Type of Organization</th>
                  <td>
                    <p *ngIf="member.orgType == null  || member.orgType == ''">
                      --</p>
                    <p *ngIf="member.orgType != null">{{member.orgType}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Type of Business</th>
                  <td>
                    <p *ngIf="member.businessType == null  || member.businessType == ''">
                      --</p>
                    <p *ngIf="member.businessType != null">{{member.businessType}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Nature of Business</th>
                  <td>
                    <p *ngIf="member.businessName == null  || member.businessName == ''">
                      --</p>
                    <p *ngIf="member.businessName != null">{{member.businessName}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Postal Address </th>
                  <td>
                    <p *ngIf="member.postalAddress == null  || member.postalAddress == ''">
                      --</p>
                    <p *ngIf="member.postalAddress != null">{{member.postalAddress}}</p>
                  </td>
                </tr>
                <tr>
                  <th>City </th>
                  <td>
                    <p *ngIf="member.city == null  || member.city == ''">
                      --</p>
                    <p *ngIf="member.city != null">{{member.city}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Contact Person </th>
                  <td>
                    <p *ngIf="member.contactPerson == null  || member.contactPerson == ''">
                      --</p>
                    <p *ngIf="member.contactPerson != null">{{member.contactPerson}}</p>
                  </td>
                </tr>
                <tr>
                  <th>WhatsApp Number </th>
                  <td>
                    <p *ngIf="member.whatsappNo == null  || member.whatsappNo == ''">
                      --</p>
                    <p *ngIf="member.whatsappNo != null">{{member.whatsappNo}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Member of AFTC </th>
                  <td>
                    <p *ngIf="member.memberOfAftc == null  || member.memberOfAftc == ''">
                      --</p>
                    <p *ngIf="member.memberOfAftc != null">{{member.memberOfAftc}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Annual Turnover Group</th>
                  <td>
                    <p *ngIf="member.annualTurnoverGroup == null  || member.annualTurnoverGroup == ''">
                      --</p>
                    <p *ngIf="member.annualTurnoverGroup != null">{{member.annualTurnoverGroup}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Forum</th>
                  <td>
                    <ng-container *ngIf="member.forum">
                      {{ getUnique(member.forum)}}
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <th>payment Status </th>
                  <td>
                    <p *ngIf="member.paymentStatus == null  || member.paymentStatus == ''">
                      --</p>
                    <p *ngIf="member.paymentStatus != null">{{member.paymentStatus}}</p>
                  </td>
                </tr>
                <tr>
                  <th>Transaction Id </th>
                  <td>
                    <p
                      *ngIf="member.transactionDetails.bankTxnId == null  || member.transactionDetails.bankTxnId == ''">
                      --</p>
                    <p *ngIf="member.transactionDetails.bankTxnId != null">{{member.transactionDetails.bankTxnId}}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>Transaction Date</th>
                  <td>
                    <p *ngIf="member.transactionDetails.updateAt == null  || member.transactionDetails.updateAt == ''">
                      --</p>
                    <p *ngIf="member.transactionDetails.updateAt!= null">{{member.transactionDetails.updateAt |
                      date: "dd-MM-yyyy"}}</p>
                  </td>
                </tr>
                <tr *ngIf="member.manualImagePath != ''">
                  <th>Manual Receipt Image</th>
                  <td>
                    <i class="fa fa-eye" title="Click to View Image" style="background-color: white;"
                      (click)="photoView(member.membershipDetailsId,'manual_form');"> Click here</i>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaders">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
