<app-toll-gate-navbar *ngIf="userType == 'ADMIN'"></app-toll-gate-navbar>
<div class="container-fulid">
  <div *ngIf="userType == 'ADMIN'" class="row"></div>
  <div class="row mt-4">
    <div class="col-md-8 justify-content-start">
      <div class="div mb-2 mt-4">
      </div>
    </div>
  </div>
  <div class="row ms-2">
    <div class="col-md-6 justify-content-start">
      <div class="div mb-2 mt-3">
        <div class="row">
          <div class="col-lg-4 mt-2">
            <div class="form-floating">
              <select class="form-select" placeholder="Vehicle Type" (change)="tollGateFilter($event, 'type')">
                <option selected>Select Vehicle Type</option>
                <option *ngFor="let type of this.getVehicleType">{{type}}</option>
              </select>
              <label>Vehicle Type</label>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="form-floating">
              <select class="form-select" placeholder="Date" (change)="tollGateFilter($event, 'date')">
                <option selected>Select Date</option>
                <option *ngFor="let date of this.getDateValue">{{date}}</option>
              </select>
              <label>Date</label>
            </div>
          </div>
          <div class="col-lg-4 mt-2">
            <div class="form-floating">
              <select class="form-select" placeholder="Cash Deposit" (change)="tollGateFilter($event, 'cash')">
                <option selected>Select Cash Deposit</option>
                <option value="Deposit">Deposit</option>
                <option value="Not Deposit">Not Deposit</option>
              </select>
              <label>Cash Deposit</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="div">
        <div class="row">
          <div class="col-lg-4 mt-4">
            <div class="form-floating">
              <input type="text" class="form-control" [value]="this.totalCounts" placeholder="Search" readonly>
              <label>Total Counts</label>
            </div>
          </div>
    <div class="col-lg-3 mt-2">
      <div class="div mb-1 mt-4">
        <button type="button" class="btn btn-success" (click)="generateVistorsXLSX()">Download Excel</button>
      </div>
    </div>
    <div class="col-lg-4 mt-2">
      <div class="div mb-1 mt-4">
        <button type="button" class="btn btn-primary" (click)="modelPopup()">Generate IRM Invoice</button>
      </div>
    </div>
    </div>
    </div>
    </div>
  </div>

  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
        <tr class="sticky-top">
          <th>No</th>
          <th>Vehicle Type</th>
          <th>Vehicle Number</th>
          <th>Vehicle Entry Date</th>
          <th>Amount(₹)</th>
          <th>Cash Deposit</th>
          <th>Print</th>
        </tr>
        </thead>
        <tbody *ngFor="let aftc of this.getPassDetails | filter: searchText;let i = index">
        <tr>
          <td>{{i + 1}}</td>
          <td>{{aftc.tollgateVehicleDetails.vehType}}</td>
          <td>{{aftc.tollgateVehicleDetails.vehNumberPlate}}</td>
          <td>{{aftc.vehEntryDateTime * 1000 | date: 'dd/MM/yyyy'}}</td>
          <td>{{(formatCharges(aftc.charges))}}</td>
          <td>{{ aftc.cashDeposit ? 'Deposit' : 'Not Deposit' }}</td>
          <td><i class="fas fa-print" (click)="printDetails(aftc)"></i></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal" tabindex="-1" [style.display]="isModalShow ? 'block' : 'none'" role="dialog">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><b>No Cash Deposit Details</b></h5>
        <input type="text" class="col-4 ms-2 mt-1 font-weight-bolder" [value]="'₹' + formatCharges(this.totalAmount)"
               disabled>
        <button type="button" class="close" data-dismiss="modal" (click)="cencel()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive p-0 text-center" style="height: 350px;overflow-y: scroll;">
          <table class="table table-striped table-bordered table-head-fixed">
            <thead class="popuphead">
            <tr>
              <th>S.No</th>
              <th>Vehicle Type</th>
              <th>Vehicle Number</th>
              <th>Amount(₹)</th>
            </tr>
            </thead>
            <tbody *ngIf="this.cashDepositValues.length > 0; else noDataFound">
            <tr *ngFor="let same of this.cashDepositValues; let i =index;">
              <td>{{i + 1}}</td>
              <td>{{ same.tollgateVehicleDetails.vehType}}</td>
              <td>{{ same.tollgateVehicleDetails.vehNumberPlate}}</td>
              <td>{{ formatCharges(same.charges)}}</td>
            </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="saveCash()">Ok</button>
        <button type="button" class="btn btn-danger" (click)="cencel()" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
